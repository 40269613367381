/* eslint-disable */
export default {
  "ID": "zh_TW",
  "": "",
  "system_title": "亞洲指標電子報",
  "common_to_official_site": "前往官網",
  "common_login": "帳號登入",
  "common_change_password": "修改密碼",
  "common_logout": "登出",
  "common_select_placeholder": "請選擇",
  "common_filter_title": "篩選設定",
  "common_owner": "負責人",
  "common_collaborator": "協作者",
  "common_submit": "套用",
  "common_clean": "清除",
  "common_confirm": "確認",
  "common_cancel": "取消",
  "common_close": "關閉",
  "common_copy_success": "複製成功",
  "common_apply": "送出",
  "common_create": "新增",
  "common_brand": "品牌",
  "common_brand_category": "品類",
  "common_product_line": "產品線",
  "common_product": "產品",
  "common_tag": "標籤",
  "common_tag_child": "子標籤",
  "common_source_category_news": "新聞",
  "common_source_category_forum": "論壇",
  "common_source_category_facebook": "facebook",
  "common_source_category_instagram": "Instagram",
  "common_source_category_thread": "Threads",
  "common_source_category_video": "影片",
  "common_source_category_others": "其他",
  "common_back_page": "回上頁",
  "common_positive": "正面",
  "common_negative": "負面",
  "common_neutral": "中性",
  "common_count_unit": "筆",
  "common_no_data": "暫無資料",
  "common_volume": "聲量",
  "common_date": "日期",
  "common_loading": "資料更新中，請稍候！",
  "common_user_auth": "驗證身份中，請稍等",
  "common_error_subject_title": "主題設定項目已移除",
  "common_default_main_brand": "主品牌",
  "download_key_order": "序號",
  "download_key_title": "標題",
  "download_key_brand": "品牌",
  "download_key_author": "發文者ID",
  "download_key_date": "發文日期",
  "download_key_latest_date": "最新發表時間",
  "download_key_category": "來源類型",
  "download_key_website": "來源網站",
  "download_key_channel": "來源頻道",
  "download_key_comment_count": "總回文",
  "download_key_wom_count": "聲量",
  "download_key_sentiment": "情緒",
  "download_key_eval_type": "評價類型",
  "download_key_topic_attribute": "話題屬性",
  "download_key_report_advice": "回報建議",
  "download_key_department_attribute": "部門屬性",
  "download_key_url": "文章網址",
  "download_success": "話題列表下載成功！",
  "download_fail": "話題列表下載失敗！",
  "select_is_auto_send": "是否自動寄送",
  "select_auto_send": "自動寄送",
  "select_not_auto": "不自動寄送",
  "select_frequency_title": "電子報寄送週期",
  "select_frequency_monthly": "月報",
  "select_frequency_weekly": "週報",
  "select_frequency_daily": "日報",
  "select_frequency_today": "當日報",
  "select_frequency_noon": "當日午報",
  "select_frequency_evening": "當日晚報",
  "select_status_waiting": "等待分析",
  "select_status_begin": "分析中",
  "select_status_done": "分析成功",
  "select_status_fail": "分析失敗",
  "edm_list_page_title": "主題電子報列表",
  "edm_list_filter_pic": "負責人",
  "edm_list_add_new": "新增主題電子報",
  "edm_list_total_count": "共 %count% 筆",
  "edm_list_no_data_desc": "尚無資料或沒有符合條件的電子報！",
  "edm_list_no_filter_data_desc": "沒有符合條件的電子報，請更換搜尋條件",
  "edm_list_no_filter_data_btn": "重置條件",
  "edm_list_search_placeholder": "搜尋電子報名稱、專案ID",
  "edm_list_filter_collaborator": "協作者",
  "edm_list_table_name_title": "主題電子報名稱",
  "edm_list_table_name_desc": "點擊進入每日結果列表",
  "edm_list_table_name_copy": "複製電子報名稱",
  "edm_list_table_project_id_title": "專案ID",
  "edm_list_table_time_title": "電子報寄送範圍時間",
  "edm_list_table_auto_send_title": "是否自動寄送",
  "edm_list_table_frequency_title": "電子報寄送週期",
  "edm_list_table_template_title": "電子報模板主題",
  "edm_list_table_collaborator_title": "所屬協作者",
  "edm_list_table_collaborator_more": "more...",
  "edm_list_table_person_in_charge_title": "負責人",
  "edm_list_table_last_changed_time_title": "最後更新異動時間",
  "edm_list_table_project_status_title": "專案分析狀態\n(分析執行時間)",
  "edm_list_table_edm_auto_status_title": "電子報自動分析狀態 \n(分析執行時間)",
  "edm_list_table_edm_manual_status_title": "電子報手動分析狀態 \n(分析執行時間)",
  "edm_list_table_action_title": "動作",
  "edm_list_table_action_edit": "編輯",
  "edm_list_table_action_re-analysis": "重新分析電子報",
  "edm_list_table_action_re-analysis_desc": "請選擇重新分析的時間範圍",
  "edm_list_table_action_re-analysis_message_success": "電子報將會進行重新分析！",
  "edm_list_table_action_re-analysis_message_error": "電子報重新分析失敗，請稍後再試。",
  "edm_list_table_action_delete": "刪除",
  "edm_list_table_action_delete_title": "確認是否刪除%name%?",
  "edm_list_table_action_delete_desc": "注意：將會永久刪除此主題電子報的所有資料。且同步刪除所屬客戶、所屬協作者的主題電子報列表數據與資料。",
  "edm_list_table_action_delete_confirm_desc": "若確認移除%name%，",
  "edm_list_table_action_delete_confirm_note": "請在下方輸入主題電子報名稱",
  "edm_list_table_action_delete_input_title": "主題電子報名稱",
  "edm_list_table_action_delete_message_success": "刪除主題電子報成功",
  "edm_list_table_action_delete_message_error": "輸入名稱錯誤，請重新確認。",
  "edm_list_table_action_download": "下載不重複發文話題列表",
  "edm_list_table_action_download_title": "下載「不重複發文話題列表」",
  "edm_list_table_action_download_desc": "請選擇下載的時間範圍",
  "edm_list_table_action_download_message_success": "下載話題列表成功",
  "edm_list_table_action_analyzing_error": "分析中，暫時無法進行操作。",
  "edm_drawer_title": "電子報模板主題設定",
  "edm_drawer_name_title": "*主題電子報名稱",
  "edm_drawer_name_placeholder": "請輸入主題電子報名稱",
  "edm_drawer_name_duplicate_error": "名稱重複，請使用其他的電子報名稱",
  "edm_drawer_name_empty_error": "電子報名稱請勿空白",
  "edm_drawer_project_title": "*選擇電子報寄送專案",
  "edm_drawer_department_placeholder": "請選擇部門",
  "edm_drawer_sales_placeholder": "請選擇專案設定負責人",
  "edm_drawer_project_placeholder": "請輸入專案名稱或ID",
  "edm_drawer_project_toast_error": "專案不存在",
  "edm_drawer_project_error": "請選擇電子報寄送專案",
  "edm_drawer_time_title": "*寄送時間起迄範圍",
  "edm_drawer_time_start_placeholder": "請選擇起始日期",
  "edm_drawer_time_end_placeholder": "請選擇結束日期",
  "edm_drawer_time_error": "請選擇電子報寄送時間區間",
  "edm_drawer_template_title": "*電子報模板",
  "edm_drawer_template_placeholder": "請選擇電子報模板",
  "edm_drawer_template_direct": "直客-行銷部門",
  "edm_drawer_template_error": "請選擇電子報模板",
  "edm_drawer_main_brand_title": "*圖表主品牌",
  "edm_drawer_main_brand_placeholder": "選擇主品牌(單選)",
  "edm_drawer_main_brand_error": "請選擇主品牌",
  "edm_drawer_main_brand_category_title": "*圖表主品類",
  "edm_drawer_main_brand_category_placeholder": "選擇主品類(單選)",
  "edm_drawer_main_brand_category_error": "請選擇主品類",
  "edm_drawer_feature_title": "*特性主題",
  "edm_drawer_feature_placeholder": "選擇特性檔案(單選)",
  "edm_drawer_feature_error": "請選擇特性主題",
  "edm_drawer_topic_setting_title": "模板話題設定",
  "edm_drawer_topic_setting_desc": "話題主題可選擇有至少一個、至多十個項目",
  "edm_drawer_topic_setting_error": "請至少新增一個話題主題",
  "edm_drawer_topic_prefix": "話題主題",
  "edm_drawer_topic_no_evaluate": "不開放評價",
  "edm_drawer_topic_has_evaluate": "開放評價",
  "edm_drawer_topic_level": "階層類型：",
  "edm_drawer_topic_main_content": "主題：",
  "edm_drawer_topic_source": "來源：",
  "edm_drawer_topic_source_suffix": "來源類型",
  "edm_drawer_topic_source_all": "全部來源類型",
  "edm_drawer_topic_source_all_nodeco": "全部",
  "edm_drawer_manage_section_title": "電子報管理與編輯條件設定",
  "edm_drawer_editor_auth_title": "主題電子報之編輯權限",
  "edm_drawer_editor_title": "所屬協作者",
  "edm_drawer_editor_placeholder": "請選擇協作者名稱",
  "edm_drawer_setting_topic_title": "定義話題編輯項目",
  "edm_drawer_setting_topic_desc": "依專案需求若不使用預設項目，可直接刪除後自行新增項目。",
  "edm_drawer_setting_topic_add_desc": "點擊新增自訂義屬性",
  "edm_drawer_setting_topic_add_success": "新增標籤%name%成功",
  "edm_drawer_setting_topic_edit_error": "編輯的標籤不存在，請重新確認！",
  "edm_drawer_setting_topic_add_error_same_name": "無法新增！類別%type%已存在相同名稱的標籤！",
  "edm_drawer_setting_topic_sentiment_title": "評價類型",
  "edm_drawer_setting_topic_sentiment_positive": "正面",
  "edm_drawer_setting_topic_sentiment_negative": "負面",
  "edm_drawer_setting_topic_sentiment_neutral": "一般",
  "edm_drawer_setting_topic_attribute_title": "話題屬性",
  "edm_drawer_setting_topic_attribute_recommend": "請益推薦",
  "edm_drawer_setting_topic_attribute_gossip": "日常閒聊",
  "edm_drawer_setting_topic_attribute_brand_image": "品牌形象",
  "edm_drawer_setting_topic_attribute_brand_compare": "品牌比較",
  "edm_drawer_setting_topic_attribute_product_compare": "產品比較",
  "edm_drawer_setting_topic_attribute_product_experience": "產品心得",
  "edm_drawer_setting_topic_attribute_coupon": "優惠活動",
  "edm_drawer_setting_topic_attribute_place_related": "通路相關",
  "edm_drawer_setting_topic_attribute_price_related": "價格相關",
  "edm_drawer_setting_topic_attribute_service_related": "服務相關",
  "edm_drawer_setting_topic_reply_title": "回報建議",
  "edm_drawer_setting_topic_reply_need": "必須回應",
  "edm_drawer_setting_topic_reply_suppose": "建議回覆",
  "edm_drawer_setting_topic_reply_no_need": "不需處理",
  "edm_drawer_setting_topic_reply_keep_watching": "繼續觀察",
  "edm_drawer_setting_topic_reply_others": "其他",
  "edm_drawer_setting_topic_department_title": "部門屬性",
  "edm_drawer_setting_topic_department_brand": "品牌部",
  "edm_drawer_setting_topic_department_product": "產品部",
  "edm_drawer_setting_topic_department_PR": "公關部",
  "edm_drawer_setting_topic_department_marketing": "行銷部",
  "edm_drawer_setting_receiver_setting_section_title": "收件人設定",
  "edm_drawer_setting_mail_title": "*電子報主旨",
  "edm_drawer_setting_mail_title_placeholder": "輸入電子報主題名稱(至多60字元)",
  "edm_drawer_setting_mail_error": "請輸入電子報主旨",
  "edm_drawer_setting_receiver_setting_title": "新增收件人",
  "edm_drawer_setting_receiver_setting_desc": "輸入收件人信箱",
  "edm_drawer_setting_receiver_setting_success": "新增收件人成功",
  "edm_drawer_setting_receiver_list_title": "*收件人列表",
  "edm_drawer_setting_receiver_list_placeholder": "尚未新增收件人",
  "edm_drawer_setting_receiver_error": "信箱格式不符合，請重新確認！",
  "edm_drawer_setting_receiver_same_error": "收件人已存在，請重新確認！",
  "edm_drawer_setting_receiver_nodata_error": "請至少新增一個電子報收件人",
  "edm_drawer_setting_frequency_title": "*電子報寄送週期",
  "edm_drawer_setting_frequency_error": "請至少選擇一個電子報寄送週期",
  "edm_drawer_setting_frequency_desc": "結果產出週期規則\n\n日報：寄送前一天。\n當日報: 總共寄送兩次。下午12點寄送當天 00:00~10:00、下午6點寄送當天 00:00~16:00。\n週報：每週第一天寄送上週。\n月報：每月第一天寄送上個月。",
  "edm_drawer_setting_frequency_placeholder": "選擇週期方式",
  "edm_drawer_setting_auto_send_title": "電子報是否自動寄送",
  "edm_drawer_setting_auto_send_today_title": "當日報包含",
  "edm_drawer_setting_auto_send_today_noon": "當日 12pm 寄送午報",
  "edm_drawer_setting_auto_send_today_night": "當日 6pm 寄送晚報",
  "edm_setting_modal_topic_setting_title": "話題主題設定",
  "edm_setting_modal_topic_setting_open_edit": "開放編輯評價類型",
  "edm_setting_modal_topic_setting_select_level": "選擇階層類型",
  "edm_setting_modal_topic_setting_brand_category_placeholder": "選擇品類(單選)",
  "edm_setting_modal_topic_setting_brand_placeholder": "選擇品牌(單選)",
  "edm_setting_modal_topic_setting_product_placeholder": "選擇產品(單選)",
  "edm_setting_modal_topic_setting_product_line_placeholder": "選擇產品線(單選)",
  "edm_setting_modal_topic_setting_tag_placeholder": "選擇標籤(單選)",
  "edm_setting_modal_topic_setting_sub_tag_placeholder": "選擇子標籤(單選)",
  "edm_setting_modal_topic_setting_brand_select_all": "品牌全選",
  "edm_setting_modal_topic_setting_brand_category_select_all": "品類全選",
  "edm_setting_modal_topic_setting_source_title": "來源",
  "edm_setting_modal_topic_setting_source_placeholder": "請選擇來源（若未指定來源類型，將等同全選來源類型）",
  "edm_setting_modal_topic_setting_category": "來源類型",
  "edm_setting_modal_topic_setting_website": "來源網站",
  "edm_setting_modal_topic_setting_channel": "來源頻道",
  "edm_setting_modal_topic_setting_source_alert": "請先選擇上一個階層資料",
  "edm_setting_modal_topic_setting_select_all": "全選",
  "edm_setting_modal_topic_setting_search_placeholder": "請輸入搜尋關鍵字",
  "edm_setting_modal_topic_setting_source_block_note": "請設定來源，以便顯示資料項目",
  "edm_setting_delete_modal_tag_title": "確認是否刪除{%type%：%tag%}？",
  "edm_setting_delete_modal_tag_description": "注意：話題資料將從明天開始改變但過往資料保留。",
  "edm_setting_delete_modal_email_title": "確認是否刪除信箱：{%email%}？",
  "edm_setting_delete_modal_topic_title": "確認是否刪除話題主題\n階層類型：{%level%} \n主題：{%topic%}？",
  "edm_setting_delete_modal_topic_description": "注意：電子報將會永久刪除此話題主題的所有資料。",
  "edm_setting_drawer_message_success": "電子報設定成功！",
  "edm_setting_drawer_message_error": "電子報設定資料有誤，請重新確認",
  "edm_setting_drawer_message_analyzing_warning": "電子報分析中，無法進行編輯",
  "edm_setting_drawer_submit_button_tooltip": "送出後不會立即進行電子報分析，需等待下一次自動排程，若需要立即分析，請至電子報列表或結果列表點擊『重新分析』鈕。",
  "result_page_title": "每日結果列表",
  "result_page_email_subject": "寄送主旨",
  "result_page_filter_title": "篩選器",
  "result_page_filter_date_picker_start_placeholder": "起始時間",
  "result_page_filter_date_picker_end_placeholder": "結束時間",
  "result_page_filter_frequency_placeholder": "電子報週期類型",
  "result_page_filter_sender_placeholder": "寄件者",
  "result_page_filter_analysis_status_placeholder": "電子報自動分析狀態",
  "result_page_table_time": "電子報資料區間",
  "result_page_table_frequency": "電子報週期類型",
  "result_page_table_project_analysis_status": "專案分析狀態\n(分析執行時間)",
  "result_page_table_newsletter_analysis_auto_status": "電子報自動分析狀態 \n(分析執行時間)",
  "result_page_table_newsletter_analysis_manual_status": "電子報手動分析狀態 \n(分析執行時間)",
  "result_page_table_latest_action_time": "最新動作查詢時間",
  "result_page_table_latest_action_time_tooltip": "電子報相關人執行動作包含編輯、預覽/寄送電子報、下載話題列表",
  "result_page_table_latest_send_time": "最新寄送時間",
  "result_page_table_latest_sender": "最新寄件者",
  "result_page_table_latest_sender_system": "系統自動寄送",
  "result_page_table_action": "動作",
  "result_page_table_action_edit": "編輯",
  "result_page_table_action_preview": "預覽/寄送 電子報",
  "result_page_table_action_download": "下載話題列表",
  "result_page_table_action_download_modal_note": "確認是否下載：",
  "result_page_table_action_pm": "到專案管理工具跑資料",
  "result_page_table_action_reanalysis": "重新分析電子報",
  "result_page_table_action_reanalysis_modal_note": "確認是否重新分析：",
  "result_page_refresh_note": "重新整理頁面，將會更新電子報即時資訊",
  "result_page_status_check_fail_project": "分析中，無法編輯電子報內容",
  "result_edit_page_summary_title": "編輯每日摘要",
  "result_edit_page_preview": "預覽",
  "result_edit_page_calculate_btn": "點擊統計即時評價結果",
  "result_edit_page_calculate_message_success": "「人工討論串分類」資料已更新",
  "result_edit_page_calculate_message_error": "「人工討論串分類」資料更新失敗，請稍後再試！",
  "result_edit_page_today_volume": "今日聲量：",
  "result_edit_page_sentiment": "情緒：",
  "result_edit_page_top3_channel": "TOP3熱門的頻道來源：",
  "result_edit_page_top3_feature": "TOP3熱門的特性關鍵字：",
  "result_edit_page_manual_statistic": "人工討論串分類",
  "result_edit_page_eval_type": "評價類型：",
  "result_edit_page_manual_tooltip": "有編輯話題，將會出現下方的數據摘要",
  "result_edit_page_memo": "自動摘要",
  "result_edit_page_memo_placeholder": "總體文字最多限制5000字元",
  "result_edit_page_memo_update_success": "自動摘要更新成功",
  "result_edit_page_edit_topic": "編輯話題列表",
  "result_edit_page_select_topic_placeholder": "請選擇主題",
  "result_edit_page_topic_table_order": "序號",
  "result_edit_page_topic_table_title": "標題",
  "result_edit_page_topic_table_category": "來源類型",
  "result_edit_page_topic_table_channel": "來源頻道",
  "result_edit_page_topic_table_wom_count": "聲量",
  "result_edit_page_topic_table_sentiment": "主文情緒",
  "result_edit_page_topic_table_eval_type": "評價類型",
  "result_edit_page_topic_table_eval_type_placeholder": "請選擇評價類型",
  "result_edit_page_topic_table_topic_attribute": "話題屬性",
  "result_edit_page_topic_table_topic_attribute_placeholder": "請選擇話題屬性",
  "result_edit_page_topic_table_report_advice": "回報建議",
  "result_edit_page_topic_table_report_advice_placeholder": "請選擇回報建議",
  "result_edit_page_topic_table_department": "部門屬性",
  "result_edit_page_topic_table_department_placeholder": "請選擇部門屬性",
  "result_edit_page_topic_table_should_send": "顯示不寄出標記",
  "preview_page_title": "專案每日電子報",
  "preview_page_summary_section_title": "每日摘要",
  "preview_page_today_volume": "今日聲量：",
  "preview_page_sentiment": "情緒：",
  "preview_page_top3_channel": "TOP3熱門的頻道來源：",
  "preview_page_top3_feature": "TOP3熱門的特性關鍵字：",
  "preview_page_manual_statistic": "人工討論串分類",
  "preview_page_eval_type": "評價類型：",
  "preview_page_memo": "自動摘要：",
  "preview_page_volume_analysis_title": "聲量分析",
  "preview_page_main_brand_trend": "聲量趨勢",
  "preview_page_competitor_ranking": "競品排行榜",
  "preview_page_date": "日期範圍：",
  "preview_page_date_current": "本期：",
  "preview_page_date_previous": "前期：",
  "preview_page_volume_calculate": "本期總聲量 - 前期總聲量 = ",
  "preview_page_topics_title": "話題列表",
  "preview_page_subject_prefix": "主題",
  "preview_page_topic_table_order": "序號",
  "preview_page_topic_table_title": "標題",
  "preview_page_topic_table_category": "來源類型",
  "preview_page_topic_table_channel": "來源頻道",
  "preview_page_topic_table_wom_count": "聲量",
  "preview_page_topic_table_eval_type": "評價類型",
  "preview_page_topic_table_topic_attribute": "話題屬性",
  "preview_page_topic_table_report_advice": "回報建議",
  "preview_page_topic_table_department_attribute": "部門屬性",
  "preview_page_topic_table_sentiment": "主文情緒",
  "preview_page_topic_table_more_topic": "查看更多話題",
  "preview_page_feature_analysis_title": "特性關鍵字分析",
  "preview_page_word_cloud": "文字雲TOP30",
  "preview_page_feature_keyword": "特性關鍵字",
  "preview_page_feature_table_ranking": "排名",
  "preview_page_feature_table_keyword": "關鍵字",
  "preview_page_feature_table_count": "提及數",
  "preview_page_right": "本網站版權屬於亞洲指標數位行銷顧問股份有限公司所有，未經本站同意，禁止轉載文字資料及圖案。 \nCopyright © 2022 INDEXASIA INC. All Rights Reserved.",
  "preview_page_manual_send": "手動寄送電子報",
  "preview_page_manual_send_message_success": "電子報寄送成功！",
  "preview_page_manual_send_message_error": "電子報寄送失敗，請稍後再試。",
  "preview_page_feature_keyword_no_data": "暫無特性關鍵字資料！",
  "login_page_title": "專案電子報",
  "login_page_desc": "最懂行銷人的大數據工具\n提升工作效率加深分析說服力",
  "login_page_btn": "登入",
  "common_user_not_found": "使用者不存在，請重新登入！",
  "common_user_no_permission": "使用者沒有電子報系統的權限，請更換帳號！",
  "common_user_error_description": "取得帳號資料失敗\n(您尚未登入或並未擁有系統權限)",
  "common_user_error_btn": "返回登入",
  "api_get_owner_error": "無法取得負責人資料，請稍後再試",
  "api_get_collaborator_error": "無法取得協作者資料，請稍候再試",
  "api_get_edm_list_error": "無法取得電子報列表，請稍後再試",
  "api_get_edm_list_status_warning": "取得電子報分析狀況異常！",
  "api_delete_edm_error": "刪除電子報失敗，請稍後再試",
  "api_reanalysis_edm_error": "重新分析電子報失敗，請稍後再試",
  "api_get_edm_detail_error": "取得電子報詳情失敗，請稍候再試或檢查電子報設定是否有誤，若曾刪除主品牌，請重新設定",
  "api_get_subject_content_error": "取得主題資料失敗，請稍後再試",
  "api_get_team_list_error": "取得部門列表失敗，請稍後再試",
  "api_get_project_owner_list_error": "取得專案負責人失敗，請稍後再試",
  "api_get_project_list_error": "取得專案列表失敗，請稍後再試",
  "api_get_project_detail_error": "取得專案詳情失敗，請稍後再試",
  "api_post_create_edm_error": "建立電子報失敗，請稍後再試",
  "api_put_update_edm_error": "更新電子報失敗，請稍後再試",
  "api_post_inform_update_error": "電子報設定成功，但通知設定失敗，請稍後再試",
  "api_get_edm_status_error": "取得電子報狀態失敗，請稍後再試",
  "api_get_edm_result_list_error": "無法取得每日結果列表，請稍後再試",
  "api_get_edm_result_detail_error": "取得每日結果詳情失敗，請稍後再試",
  "api_get_edm_result_summary_error": "取得每日結果摘要失敗，請稍後再試",
  "api_get_project_source_error": "取得專案來源資料失敗，請稍後再試",
  "api_get_project_angle_error": "取的專案切角失敗，請稍後再試",
  "api_get_project_level_one_data_error": "取得專案品牌/品類資料失敗，請稍後再試，若曾刪除主品牌，請重新設定",
  "api_get_project_level_two_data_error": "取得專案產品線/品牌資料失敗，請稍後再試",
  "api_get_project_level_three_data_error": "取得專案產品資料失敗，請稍後再試",
  "api_get_project_tag_data_error": "取得專案標籤資料失敗，請稍後再試",
  "api_get_project_level_one_list_error": "取得專案品牌/品類列表失敗，請稍後再試",
  "api_get_project_level_two_list_error": "取得專案產品線/品牌資料失敗，請稍後再試",
  "api_get_project_level_three_list_error": "取得專案產品資料失敗，請稍後再試",
  "api_get_project_level_tag_list_error": "取得專案標籤資料失敗，請稍後再試",
  "api_level_one_not_found_error": "品牌/品類不存在，請重試",
  "api_get_main_chart_data_error": "取得主品牌圖表資料失敗，請稍後再試",
  "api_get_topics_error": "取得話題列表失敗，請稍後再試",
  "api_get_cloud_chart_error": "取得文字雲資料失敗，請稍後再試",
  "api_get_manual_summary_error": "無法重新計算手動評價資料，請稍候再試",
  "api_post_memo_error": "更新自動摘要失敗，請稍後再試",
  "api_put_update_eval_type_error": "更新評價類型失敗，請稍後再試",
  "api_put_update_topic_attribute_error": "更新話題屬性失敗，請稍後再試",
  "api_put_update_report_advice_error": "更新回報建議失敗，請稍後再試",
  "api_put_update_department_attribute_error": "更新部門屬性失敗，請稍後再試",
  "api_put_update_should_send_error": "更新顯示不寄出標記失敗，請稍候再試"
};
